.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.projects-description {
	width: 85% !important;
}

.projects-section-header {
	margin-top: 30px;
}

@media (max-width: 1024px) {
	.projects-description {
		width: 100% !important;
	}
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 120px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}
