@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

@import "../../../data/styles.css";

.experience-item {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.experience-item a {
	text-decoration: none;
}

.experience-item:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.experience-item:hover .experience-item-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.experience-item-container {
	padding: 30px;
}

.experience-item-country {
	color: var(--secondary-color);
	font-size: 12px;
	margin-bottom: 1em;
}

.experience-item-img {
	max-width: 100%;
	border-radius: 20px;
}

.experience-item-logo {
	width: 30px;
}

.experience-item-logo img {
	width: 100%;
}

.experience-item-title {
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	font-weight: 700;
}

.experience-item-description {
	color: var(--secondary-color);
}

.experience-item-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
}